<template>
  <article id="faq-container" class="mt-10">
    <v-row class="m-0 faq-hero-bg">
      <v-col class="col-12 col-md-6 hero-left-col order-2 order-md-1">
        <div id="faq-hero-text-container">
          <h1 class="mt-md-16 mt-6">
            {{ $t('YouHaveQuestionWeHaveAnswers') }}
          </h1>
          <span id="hero-subtitle">{{ $t('SchedulingBillingWarrantyOther') }}</span>
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 d-flex justify-md-end order-1 order-md-2 hero-right-col">
        <v-img class="mt-12 px-3"
               :max-width="$vuetify.breakpoint.mdAndUp
                        ? 500
                        : 300"
               :lazy-src="require(`../assets/images/faq-hero-image.webp`)"
               :src="require(`../assets/images/faq-hero-image.webp`)"/>
      </v-col>
      <v-col class="col-12 d-flex justify-center order-3 mt-5">
        <a href="#content">
          <v-icon
            class="about-us-arrow-down"
            width="34"
            height="34"
            color="black" >
            mdi-arrow-down-circle-outline
          </v-icon>
        </a>
      </v-col>
    </v-row>
    <div id="content"></div>
    <v-row id="faqs">
      <v-col cols="12" md="7" class="mx-auto">
        <v-subheader class="panel-sub-header">{{ $t('faq.section1.header') }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel flat>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section1.questions.q1') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section1.answers.a1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section1.questions.q2') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section1.answers.a2') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section1.questions.q3') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section1.answers.a3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section1.questions.q4') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section1.answers.a4') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="7" class="mx-auto">
        <v-subheader class="panel-sub-header">{{ $t('faq.section2.header') }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section2.questions.q1') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section2.answers.a1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section2.questions.q2') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section2.answers.a2') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section2.questions.q3') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section2.answers.a3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="7" class="mx-auto">
        <v-subheader class="mt-10 panel-sub-header">{{ $t('faq.section3.header') }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section3.questions.q1') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section3.answers.a1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section3.questions.q2') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section3.answers.a2') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section3.questions.q3') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section3.answers.a3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
          >
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section3.questions.q4') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section3.answers.a4') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="7" class="mx-auto">
        <v-subheader class="panel-sub-header">{{ $t('faq.section4.header') }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q1') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q2') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a2') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q3') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q4') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a4') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q5') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a5') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section4.questions.q6') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section4.answers.a6') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="7" class="mx-auto">
        <v-subheader class="mt-10 panel-sub-header">{{ $t('faq.section5.header') }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-right">
              {{ $t('faq.section5.questions.q1') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('faq.section5.answers.a1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div id="faq-contact" class="col-12 col-md-7 mx-auto mt-md-16">
      <h2 class="mb-0">{{$t('faq.DoYouSeeWhatYouAreLookingFor')}}</h2>
      <span class="mt-2">{{$t('faq.EmailUs')}}<a href="mailto:tonyb@ucarmobile.com">tonyb@ucarmobile.com</a></span>
    </div>
  </article>
</template>
<style scoped lang="scss">
@import "../assets/styles/sass/pages/faq-page.scss";
</style>
<script>
export default {
  name: 'Faq',
  metaInfo: {
    title: 'UcarMobile: Auto Repair by Best Mobile Car Mechanics',
    meta: [
      {
        name: 'description',
        content: 'Best mobile car repair service near you. Easy and convenient. Reliable and trustworthy mobile auto repair. Get an instant estimate. All makes and models.',
      },
      {
        name: 'keywords',
        content: 'auto repair, mobile mechanic, find local mechanics, mobile car repair, car repair, find mechanic, mechanic, auto shop, auto repair shop, car mechanic',
      },
    ],
  },
};
</script>
