var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "mt-10", attrs: { id: "faq-container" } },
    [
      _c(
        "v-row",
        { staticClass: "m-0 faq-hero-bg" },
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6 hero-left-col order-2 order-md-1" },
            [
              _c("div", { attrs: { id: "faq-hero-text-container" } }, [
                _c("h1", { staticClass: "mt-md-16 mt-6" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("YouHaveQuestionWeHaveAnswers")) + " "
                  ),
                ]),
                _c("span", { attrs: { id: "hero-subtitle" } }, [
                  _vm._v(_vm._s(_vm.$t("SchedulingBillingWarrantyOther"))),
                ]),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "col-12 col-md-6 d-flex justify-md-end order-1 order-md-2 hero-right-col",
            },
            [
              _c("v-img", {
                staticClass: "mt-12 px-3",
                attrs: {
                  "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,
                  "lazy-src": require("../assets/images/faq-hero-image.webp"),
                  src: require("../assets/images/faq-hero-image.webp"),
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 d-flex justify-center order-3 mt-5" },
            [
              _c(
                "a",
                { attrs: { href: "#content" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "about-us-arrow-down",
                      attrs: { width: "34", height: "34", color: "black" },
                    },
                    [_vm._v(" mdi-arrow-down-circle-outline ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "content" } }),
      _c(
        "v-row",
        { attrs: { id: "faqs" } },
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "7" } },
            [
              _c("v-subheader", { staticClass: "panel-sub-header" }, [
                _vm._v(_vm._s(_vm.$t("faq.section1.header"))),
              ]),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section1.questions.q1")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section1.answers.a1")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section1.questions.q2")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section1.answers.a2")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section1.questions.q3")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section1.answers.a3")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section1.questions.q4")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section1.answers.a4")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "7" } },
            [
              _c("v-subheader", { staticClass: "panel-sub-header" }, [
                _vm._v(_vm._s(_vm.$t("faq.section2.header"))),
              ]),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section2.questions.q1")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section2.answers.a1")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section2.questions.q2")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section2.answers.a2")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section2.questions.q3")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section2.answers.a3")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "7" } },
            [
              _c("v-subheader", { staticClass: "mt-10 panel-sub-header" }, [
                _vm._v(_vm._s(_vm.$t("faq.section3.header"))),
              ]),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section3.questions.q1")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section3.answers.a1")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section3.questions.q2")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section3.answers.a2")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section3.questions.q3")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section3.answers.a3")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section3.questions.q4")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section3.answers.a4")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "7" } },
            [
              _c("v-subheader", { staticClass: "panel-sub-header" }, [
                _vm._v(_vm._s(_vm.$t("faq.section4.header"))),
              ]),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q1")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a1")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q2")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a2")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q3")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a3")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q4")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a4")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q5")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a5")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section4.questions.q6")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section4.answers.a6")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "7" } },
            [
              _c("v-subheader", { staticClass: "mt-10 panel-sub-header" }, [
                _vm._v(_vm._s(_vm.$t("faq.section5.header"))),
              ]),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-right" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("faq.section5.questions.q1")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("faq.section5.answers.a1")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-12 col-md-7 mx-auto mt-md-16",
          attrs: { id: "faq-contact" },
        },
        [
          _c("h2", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.$t("faq.DoYouSeeWhatYouAreLookingFor"))),
          ]),
          _c("span", { staticClass: "mt-2" }, [
            _vm._v(_vm._s(_vm.$t("faq.EmailUs"))),
            _c("a", { attrs: { href: "mailto:tonyb@ucarmobile.com" } }, [
              _vm._v("tonyb@ucarmobile.com"),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }